











































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import { GetBookMulu } from "@/request/storehouse";
import { BookMuluText } from "@/utils/base";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends Vue {
  private parentData: any = {
    章节: {},
    书名: {},
  };
  private data: any = {};
  private isOpenJianjie: any = false;
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private goRead(item: any) {
    this.parentData["章节"] = item;
    this.$router.push({
      path: "/main/mark/guanli/compose",
      query: {
        data: JSON.stringify(this.parentData),
      },
    });
  }
  private getMulu() {
    const params: any = {
      params: {
        book_id: this.data.book_id,
      },
    };
    GetBookMulu(this, params).then((res: any) => {
      this.data = res;
    });
  }
  private mounted() {
    if (this.$route.query.data) {
      const query: any = JSON.parse(this.$route.query.data as any);
      this.parentData = query;
      this.data.book_id = this.parentData["书名"]._id;
      this.getMulu();
    }
  }
}
